<template>
  <div>
    <div class="container space-2 space-bottom-3">
      <div class="w-md-85 w-lg-75 text-center mx-auto mb-5">
        <h2>{{ $t('AboutPage.AwardsSection.Title') }}</h2>
      </div>
      <div class="row">
        <div v-for="item in award_item_list" :key="item.title" class="col-md-4 mb-3">
          <div class="card bg-img-hero h-100" :style="{'background-image': 'url(' + require('@/assets/svg/components/general-bg-6.svg') + ')'}">
            <div class="card-body text-center pb-0">
              <div class="avatar avatar-xxl mb-3">
                <img class="avatar-img" :src="require('@/assets/img/awards/' + item.image_url)">
              </div>
              <h3 class="text-white text-center">{{ $t(item.title) }}</h3>
              <p class="text-white text-center">{{ $t(item.subtitle) }}</p>
            </div>
            <div class="card-footer pt-0 border-0 bg-transparent">
              <hr class="solid">
              <h4 class="text-white-70 text-center">{{ $t(item.organization) }}</h4>
              <span class="text-white-70 d-block font-weight-bold text-center">{{ item.year }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA',
  data() {
    return {
      award_item_list: [
        {
          image_url: 'fintech-awards-2020.jpg',
          title: 'AboutPage.AwardsSection.Award1.Title',
          subtitle: 'AboutPage.AwardsSection.Award1.Description',
          organization: 'AboutPage.AwardsSection.Award1.Organization',
          year: '2020'
        },
        {
          image_url: 'hk-ict-awards-2020-silver.jpg',
          title: 'AboutPage.AwardsSection.Award2.Title',
          subtitle: 'AboutPage.AwardsSection.Award2.Description',
          organization: 'AboutPage.AwardsSection.Award2.Organization',
          year: '2020'
        },
        {
          image_url: 'ifta-2019.jpg',
          title: 'AboutPage.AwardsSection.Award3.Title',
          subtitle: 'AboutPage.AwardsSection.Award3.Description',
          organization: 'AboutPage.AwardsSection.Award3.Organization',
          year: '2020'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
