<template>
  <div id="mission" class="position-relative overflow-hidden bg-light">
    <div class="container space-2">
      <div class="w-md-85 w-lg-75 text-center mx-auto">
        <h2 class="mb-4">{{ $t('AboutPage.MissionSection.Title') }}</h2>
        <span v-html="$t('AboutPage.MissionSection.Description')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mission'
}
</script>

<style lang="scss" scoped>
@import '~slick-carousel/slick/slick.css';

</style>
