<template>
  <div>
    <Hero />
    <Mission />
    <Awards />
    <JoinUs />
  </div>
</template>

<script>
import Hero from './components/hero'
import Mission from './components/mission'
import Awards from './components/awards'
import JoinUs from './components/join-us'

export default {
  name: 'About',
  components: {
    Hero,
    Mission,
    Awards,
    JoinUs
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'About Us | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/about' }
      ],
      meta: [
        { name: 'description', content: 'We\'re Making API Economy Accessible to Everyone.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'About Us | OpenAPIHub' },
        { property: 'og:description', content: 'We\'re Making API Economy Accessible to Everyone.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/about' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
