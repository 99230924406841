<template>
  <div class="position-relative overflow-hidden">
    <div class="container space-2 space-bottom-3">
      <div class="w-md-85 w-lg-65 mx-auto text-center">
        <h1 class="">{{ $t('AboutPage.HeroSection.Title') }}</h1>
        <p class="lead">{{ $t('AboutPage.HeroSection.Description') }}</p>
      </div>

      <div class="position-relative w-lg-65 text-center mx-lg-auto mt-11">
        <div class="position-relative shadow-lg z-index-2 rounded-lg">
          <img class="img-fluid rounded-lg" src="@/assets/img/images/oah-team.jpg">
        </div>

        <figure class="max-w-15rem w-100 position-absolute top-0 right-0">
          <div class="mt-n11 mr-n11">
            <img class="img-fluid" src="@/assets/svg/components/circled-stripe.svg">
          </div>
        </figure>
        <figure class="max-w-15rem w-100 position-absolute bottom-0 left-0">
          <div class="mb-n7 ml-n7">
            <img class="img-fluid" src="@/assets/svg/components/circled-stripe.svg">
          </div>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
</style>
