<template>
  <div class="position-relative">
    <div class="container space-bottom-3">
      <div class="rounded overflow-hidden space-top-2 space-top-lg-0 pl-5 pl-md-8 shadow" style="background-color:#EAF4FD">
        <div class="row justify-content-lg-between align-items-lg-center gutters">
          <div class="col-lg-6 col-xl-7">
            <div class="mb-4">
              <h2>{{ $t('AboutPage.JoinUsSection.Title') }}</h2>
              <p>{{ $t('AboutPage.JoinUsSection.Description') }}</p>
            </div>
            <a href="https://www.benovelty.com/careers" class="btn btn-primary btn-wide" target="_blank">
              {{ $t('AboutPage.JoinUsSection.Button') }}
            </a>
          </div>

          <div class="col-lg-6 col-xl-5 space-top-lg-1 ml-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/career-1.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BeProvider'
}
</script>

<style lang="scss" scoped>
</style>
